.SoftwareSection {
  width: 100vw;
  max-width: 1366px;
  height: fit-content;
  padding: var(--sectionPadding);
  /* margin: 100px 0px; */
  padding-top: 66px;
}
.HeadingsAndButtonsContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HeadingsContainer {
  width: 60%;
}
.ButtonsContainer {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
}
.ButtonsContainer .universal-button {
  margin: 0px 4px;
}
.GooglePlayImg {
  width: 90px;
}
.WebAppImg {
  width: 110px;
}
@media (max-width: 768px) {
  .HeadingsAndButtonsContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .HeadingsContainer {
    width: 100%;
  }
}

/* software features stylings */
.SoftwareFeaturesContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
}
.SoftwareFeatureCard {
  width: 33%;
  height: fit-content;
  padding-right: 12px;
}
.FeatureUpperSection {
  width: 100%;
  height: fit-content;
  display: flex;
  margin-bottom: 15px;
}
.FeatureUpperSection h1{
  width: 60%;
}
.FeatureIcon {
  width: 42px;
  height: 42px;
  background-color: var(--AccentGreen);
  color: var(--PrimaryGreen);
  margin-right: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .SoftwareFeaturesContainer {
    flex-direction: column;
  }
  .SoftwareFeatureCard {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (min-width: 1366px) {
  .SoftwareFeatureCard {
    padding-right: 60px;
  }
}

/* software animation styling section */
.SoftwareAnimationSection {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transform: translateY(-30px);
}

.AnimBlock {
  /* border: 3px solid orange; */
  width: 760px;
  height: 520px;
  position: relative;
  transform: translate(0px, 30px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.DesktopAnimation {
  width: 680px;
  height: 520px;
  position: absolute;
}
.MobileAnimation {
  width: 340px;
  height: 480px;
  position: absolute;
  right: 0;
  bottom: -30px;
}
.DecorativeRectangle {
  background-color: var(--AccentGreen);
  width: 800px;
  height: 180px;
  border-radius: 114px;
  position: absolute;
  bottom: 0;
}
/* @media (min-width: 1440px) {
  .SoftwareAnimationSection {
    transform: translateY(100px);
  }
} */
@media (max-width: 992px) {
  .MobileAnimation {
    display: none;
  }
  .DecorativeRectangle {
    width: 660px;
    height: 140px;
  }
  .DesktopAnimation {
    width: 520px;
    height: 460px;
  }
  .AnimBlock {
    transform: translate(0px, 80px);
  }
}
@media (max-width: 768px) {
  .DecorativeRectangle {
    width: 520px;
  }
}
@media (max-width: 620px) {
  .DecorativeRectangle {
    width: 380px;
  }
  .MobileAnimation {
    display: block;
    width: 470px;
    height: 580px;
    /* border: 3px solid black; */
    left: 50%;
    transform: translateX(-50%);
  }
  .DesktopAnimation {
    display: none;
  }
  .AnimBlock {
    transform: translate(10px, 70px);
  }
}
@media (max-width: 460px){
  .DecorativeRectangle{
    width: 320px;
  }
}
