/* new website */
.benefitsWrapper {
  width: 100vw;
  max-width: 1366px;
  height: fit-content;
  padding: var(--sectionPadding);
  /* margin: 40px 0px; */
}

.benefitsCardsWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  margin: 30px 0px;
}
.benefitCardRow {
  width: 50%;
  display: flex;
  justify-content: space-around;
}
.benefitCard {
  width: 48%;
  height: 206px;
  background-color: rgba(0, 255, 255, 0.356);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0px 50px;
}
.c1 {
  background-color: #d2fcfc;
}
.c2 {
  background-color: #fdf8e6;
}
.c3 {
  background-color: #e0f0e5;
}
.c4 {
  background-color: #d3e4fe;
}

/* modifying the default styles for specific use */
.benefitCard .LandingHeading {
  /* font-size: 34px; */
  margin: 0;
}
.benefitCard .universal-paragraphs {
  font-family: "Clash Display", sans-serif;
  font-weight: 500;
  margin: 0;
}
.c1 .LandingHeading,
.c1 .universal-paragraphs {
  color: #1ab8b8;
  transition: 0.3s cubic-bezier(0,.95,0,1)
}
.c2 .LandingHeading,
.c2 .universal-paragraphs {
  color: #ffcb00;
  transition: 0.3s cubic-bezier(0,.95,0,1)
}
.c3 .LandingHeading,
.c3 .universal-paragraphs {
  color: #62b47f;
  transition: 0.3s cubic-bezier(0,.95,0,1)
}
.c4 .LandingHeading,
.c4 .universal-paragraphs {
  color: #617cdd;
  transition: 0.3s cubic-bezier(0,.95,0,1)
}


.c1:hover .LandingHeading {
  transform: scale(0.96);
}
.c1:hover .universal-paragraphs {
  transform-origin: 50% 0%;
  transform: scale(0.96);
}

.c2:hover .LandingHeading {
  transform: scale(0.96);
}
.c2:hover .universal-paragraphs {
  transform-origin: 50% 0%;
  transform: scale(0.96);
}

.c3:hover .LandingHeading {
  transform: scale(0.96);
}
.c3:hover .universal-paragraphs {
  transform-origin: 50% 0%;
  transform: scale(0.96);
}

.c4:hover .LandingHeading {
  transform: scale(0.96);
}
.c4:hover .universal-paragraphs {
  transform-origin: 50% 0%;
  transform: scale(0.96);
}

@media (max-width: 992px) {
  .benefitsCardsWrapper {
    flex-direction: column;
  }
  .benefitCardRow {
    width: 100%;
    margin-bottom: 2vw;
  }
  .benefitCard {
    padding: 20px;
  }
}
@media (max-width: 540px) {
  .benefitCard {
    padding: 20px;
    height: 180px;
  }
}
@media (min-width: 1460px) {
  .benefitCard {
    max-width: 291px;
  }
}

/* GuestBenefitsWrapper */
.GuestBenefitsContentContainer {
  width: 100vw;
  height: fit-content;
  background-color: var(--AccentGreen);
  display: flex;
  justify-content: center;
  align-items: center;
}
.GuestBenefitsWrapper {
  width: 100%;
  max-width: 1366px;
  height: fit-content;
  padding: var(--sectionPadding);
  margin: 40px 0px;
}

.GuestBenefitsForm {
  display: flex;
  height: fit-content;
}
.GuestBenefitsForm input {
  width: 300px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: white;
}

@media (max-width: 540px) {
  .GuestBenefitsForm {
    display: flex;
    flex-direction: column;
  }
  .GuestBenefitsForm input {
    width: 100%;
  }
}
