.footer {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #363636;
  padding: 50px 0px 20px 0px;
  color: white;
  position: relative;
}
.FooterBase {
  width: 100%;
  max-width: 1366px;
  height: fit-content;
  padding: var(--sectionPadding);
  display: flex;
  justify-content: space-between;
}

.FooterCompanyDetails {
  width: 45%;
  height: fit-content;
  position: relative;
}

.FooterGrid {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 20px;
}

.FooterGrid div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  margin: 0px 4px; /*For maintaining minimum distance*/
}
.FooterGrid div p {
  color: white;
  margin: 0;
}
.FooterGrid div h1 {
  color: white;
  margin: 0;
}

.div1 {
  grid-area: 1 / 1 / 3 / 3;
}
.div1 img {
  width: 80%;
  margin-bottom: 20px;
}
.div2 {
  grid-area: 3 / 1 / 4 / 3;
}
.div3 {
  grid-area: 4 / 1 / 5 / 3;
}
.div4 {
  grid-area: 5 / 1 / 6 / 3;
}
.div5 {
  grid-area: 6 / 1 / 7 / 3;
}
.div6 {
  grid-area: 7 / 1 / 8 / 3;
}
.div7 {
  grid-area: 3 / 3 / 4 / 4;
}
.div8 {
  grid-area: 3 / 4 / 4 / 5;
}
.div9 {
  grid-area: 3 / 5 / 4 / 6;
}
.div10 {
  grid-area: 4 / 3 / 5 / 6;
}
.div11 {
  grid-area: 5 / 3 / 6 / 6;
}
.div12 {
  grid-area: 6 / 3 / 7 / 6;
}
.div13 {
  grid-area: 7 / 3 / 8 / 4;
}
.div14 {
  grid-area: 7 / 4 / 8 / 5;
}
.div15 {
  grid-area: 7 / 5 / 8 / 6;
}

.socials {
  width: fit-content;
  color: white;
  position: absolute;
  bottom: -80px;
}

.socials i {
  margin-right: 30px;
  transition: 0.1s;
}
.socials a{
  text-decoration: none;
}

#insta:hover {
  color: #ff41ad;
}
#linkedin:hover {
  color: #0e76a8;
}
#twitter:hover {
  color: #00acee;
}
#facebook:hover {
  color: #4267b2;
}
#Youtube:hover{
  color: red;
}
.RightsReserved{
  color: white;
  transform: scale(0.8);
  text-align: center;
}

@media (max-width:840px) {
  .footer{
    padding: 50px 0px 60px 0px;
  }
  .FooterBase{
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
  .FooterCompanyDetails{
    width: 100%;
  }
  .div1 img{
    display: none;
  }
  .socials{
    width: 100%;
    left: 50%;
    bottom: -60px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .RightsReserved{
    position: absolute;
    bottom: 0;
  }
}




/* querries form styling */
/* querries form styling */
/* querries form styling */
/* querries form styling */
.contactFormWrapper {
  width: 45%;
  max-width: 540px;
  height: fit-content;
}
.contactFormWrapper P {
  color: white;
}
.contactFormWrapper h1 {
  color: white;
}

.inputFeilds {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  margin-bottom: 14px;
}
.inputFeilds input {
  background-color: white;
}
.inputFeilds textarea {
  background-color: white;
}

.FormDiv1 {
  grid-area: 1 / 1 / 2 / 2;
}
.FormDiv2 {
  grid-area: 1 / 2 / 2 / 3;
}
.FormDiv3 {
  grid-area: 2 / 1 / 3 / 3;
}
.FormDiv4 {
  grid-area: 3 / 1 / 5 / 3;
}

@media (max-width: 840px) {
  .contactFormWrapper {
    width: 100%;
    padding: 6px;
    margin-bottom: 20px;
    max-width: 100%;
  }
}

