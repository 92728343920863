@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* paragraph font - Poppins */

:root {
  /* colours */
  --dark: #181a20;
  --light: #1f2127;
  --AccentGreen: #bbeecb;
  --PrimaryGreen: #0c9136;

  /* other colors */
  --YelloBG: #fff6c7;
  --YelloContrast: #ffcb00;
  --BlueBG: #d3e4fe;
  --BlueContrast: #7d96e5;
  --SkyBlueBG: #d2fcfc;
  --SkyBlueContrast: #1ab8b8;

  /* fontweights */
  --lightweight: 300;
  --regular: 400;
  --bold: 700;

  /* line heights */
  --paraLineHeight: 1.6em;
  --paraLineHeightMobile: 1.3em;
  --bigHeadingLineHeight: 112.11%;

  /* universal paddings */
  --sectionPadding: 0 70px;

  /* heading color */
  --headingColor: #474747;
  --paraColor: rgb(0, 0, 0);
}

/* media querries for variables */
@media (max-width: 992px) {
  :root {
    --sectionPadding: 0 40px;
  }
}

@media (max-width: 540px) {
  :root {
    --sectionPadding: 0 20px;
  }
}

@media (min-width: 1440px) {
  :root {
    --sectionPadding: 0px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(255, 255, 255);
  width: 100vw;
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* scrollbar designs */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0px;
  margin: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255, 20%);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(95, 95, 95);
}

::selection {
  color: #474747;
  color: var(--headingColor);
  background: #bbeecb;
}

/* All heading and paragraph universal stylings  */
.LandingHeading {
  color: #474747;
  color: var(--headingColor);
  font-size: 40px;
  font-weight: 700;
  font-weight: var(--bold);
  line-height: 112.11%;
  line-height: var(--bigHeadingLineHeight);
  letter-spacing: 0.05em;
  /* text-transform: uppercase; */
  font-family: "Clash Display", sans-serif;
}

.SmallHeadings {
  color: #474747;
  color: var(--headingColor);
  font-size: 18px;
  font-weight: 600;
  z-index: 2;
  font-family: "Clash Display", sans-serif;
  margin: 0;
  word-spacing: 1px;
}
.universal-paragraphs {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: rgb(0, 0, 0);
  color: var(--paraColor);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.universal-button {
  height: 46px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  background-color: #0c9136;
  background-color: var(--PrimaryGreen);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 10px 22px;
  z-index: 10;
  font-size: 13px;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border-radius: 100px;
  transition: 0.3s;
  text-wrap: nowrap;
}

.universal-button:hover {
  color: white;
  background: #12aa42;
}

.universalInput,
.universalTextArea {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  background: rgba(242, 242, 242, 0.7);
  border-radius: 10px;
  padding: 0px 16px;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  font-family: "Poppins", sans-serif;
}
.universalInput {
  height: 46px;
}
.universalInput:focus {
  border-bottom: 1px solid #0c9136;
  border-bottom: 1px solid var(--PrimaryGreen);
  transition: 0.5s;
}
.universalTextArea {
  height: 100%;
  padding-top: 10px;
}
.universalInput:-ms-input-placeholder {
  color: rgb(148, 148, 148);
}
.universalInput::placeholder {
  color: rgb(148, 148, 148);
}
.universalTextArea:-ms-input-placeholder {
  color: rgb(148, 148, 148);
}
.universalTextArea::placeholder {
  color: rgb(148, 148, 148);
}

.universalTextArea:focus {
  border-bottom: 1px solid #0c9136;
  border-bottom: 1px solid var(--PrimaryGreen);
  transition: 0.5s;
}

@media (max-width: 768px) {
  .LandingHeading {
    font-size: 42px;
  }
  .universal-paragraphs {
    font-size: 14px;
  }
  .universal-small-headings {
    font-size: 6vw;
  }
  .breakpoint {
    display: none;
  }
}
@media (max-width: 420px) {
  .LandingHeading {
    font-size: 28px;
  }
  .SmallHeadings {
    font-size: 16px;
  }
  .universal-paragraphs {
    font-size: 14px;
  }
  .universal-small-headings {
    font-size: 7.5vw;
  }
}

.UniversalOuterWrapper {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.UniversalInnerContainer {
  width: 100%;
  max-width: 1366px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 70px;
  padding: var(--sectionPadding);
}

.HyperLinks {
  cursor: pointer;
  transition: 0.3s;
}
.HyperLinks:hover {
  -webkit-text-decoration: underline 2px solid;
          text-decoration: underline 2px solid;
}
.dFlex {
  display: flex;
  align-items: center;
}

/* Loader stylings */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  border-radius: 50%;
}

/* .loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
} */

.loader {
  border: 3px solid white;
  border-left-color: transparent;
  width: 18px;
  height: 18px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* image opening css */
.onPhoto {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.image {
  width: auto;
  min-width: 80%;
  max-width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}

/* Landing page video buffering loader */
.LandingVideoBufferLoader {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgb(255, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-color: black;
  padding-bottom: 100px;
}
.LandingVideoBufferLoader::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    100deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 20%,
    rgba(14, 211, 63, 0.192) 50%,
    rgba(227, 227, 227, 0) 80%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation_2 0.9s linear infinite;
}

@keyframes gradient-animation_2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

/* styling fake loader for adding before every page for smooth routing */
.FakeLoaderWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.459);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

}
.FakeLoaderWrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    100deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 20%,
    rgba(255, 255, 255, 0.192) 50%,
    rgba(227, 227, 227, 0) 80%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation_2 0.9s linear infinite;
}

.Landingpage_first_page__1lLdc {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* background-color: black; */
}

.Landingpage_first_page__1lLdc video {
  width: 100vw;
  /* height: 100%; */
  opacity: 1;
  z-index: 19;
  filter: brightness(0.7);
  animation: Landingpage_fade__aGts0 0.5s linear;
}
@keyframes Landingpage_fade__aGts0 {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
.Landingpage_text_wrapper__1eVkx {
  width: 1100px;
  max-width: 1200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 19;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Landingpage_text_wrapper__1eVkx p {
  width: 70%;
}

@media (max-width: 992px) {
  .Landingpage_first_page__1lLdc {
    background-size: cover;
    background-position-x: 100%;
  }
  .Landingpage_first_page__1lLdc video {
    width: auto;
    height: 100%;
    /* opacity: 0.7; */
    filter: brightness(0.7);
  }

  .Landingpage_text_wrapper__1eVkx {
    width: 94%;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .Landingpage_text_wrapper__1eVkx p {
    width: 90%;
  }
}

@media screen and (max-width: 550px) {
  .Landingpage_first_page__1lLdc {
    background-size: cover;
    background-position-x: 90%;
  }
  .Landingpage_first_page__1lLdc video {
    width: auto;
    height: 100%;
    /* opacity: 0.7; */
    filter: brightness(0.7);
    transform: translateX(-500px);
  }
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: white;
  width: 100vw;
  margin-top: 100px;
}

.homeContainerCustomCss {
  max-width: 1366px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  padding: var(--sectionPadding);
  justify-content: flex-start;
}
/* negleting padding in bigger screens */
@media (min-width: 1366px) {
  .homeContainerCustomCss {
    padding: 0;
  }
}
.box {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-right: 100px;
}
.box p {
  margin: 0;
}
.ShiftUp {
  transform: translateY(-26px);
}

@media screen and (max-width: 992px) {
  .home {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0vw 0px;
  }
  .homeContainerCustomCss {
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .box {
    width: 100%;
    padding: 0;
  }
  .ShiftUp {
    transform: translateY(0px);
    margin-top: 80px;
    text-align: right;
    align-items: flex-end;
  }
}

@media (max-width: 540px) {
}

.product_information_wrapper {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sectionPadding);
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 120px 0px; */
}

.maxWidthContainer {
  max-width: 1366px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content_container {
  width: 50%;
  padding-right: 180px;
}
.grid_container {
  width: 50%;
}

@media (max-width: 992px) {
  .maxWidthContainer {
    flex-direction: column-reverse;
  }
  .content_container,
  .grid_container {
    width: 100%;
  }
  .content_container {
    padding: 0;
    margin-top: 60px;
  }
}

.GridParent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  width: 100%;
  height: 450px;
  max-width: 550px;
  /* min-width: 500px; */
}
@media (min-width: 1366px) {
  .GridParent {
    height: 500px;
    max-width: 650px;
  }
}
@media (max-width: 540px) {
  .GridParent {
    height: 290px;
    grid-column-gap: 6px;
    grid-row-gap: 6px;
  }
}

.one {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 1 / 1 / 3 / 3;

  display: flex;
  justify-content: center;
  align-items: center;
}
.one video{
  width: 100%;
  transform: scale(2);
}
.two {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 1 / 4 / 2 / 5;
  visibility: hidden;
}
.three {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 2 / 4 / 3 / 5;
}
.four {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 1 / 3 / 3 / 4;
}
.five {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 3 / 3 / 4 / 5;
}
.five video{
width: 100%;
}
.six {
  border-radius: 10px;
  background-color: var(--PrimaryGreen);
  grid-area: 3 / 1 / 4 / 3;
  padding: 16px;
  color: white;
  position: relative;
}
.six h1{
  color: white;
}
.icon{
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.SoftwareSection {
  width: 100vw;
  max-width: 1366px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sectionPadding);
  /* margin: 100px 0px; */
  padding-top: 66px;
}
.HeadingsAndButtonsContainer {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HeadingsContainer {
  width: 60%;
}
.ButtonsContainer {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
}
.ButtonsContainer .universal-button {
  margin: 0px 4px;
}
.GooglePlayImg {
  width: 90px;
}
.WebAppImg {
  width: 110px;
}
@media (max-width: 768px) {
  .HeadingsAndButtonsContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .HeadingsContainer {
    width: 100%;
  }
}

/* software features stylings */
.SoftwareFeaturesContainer {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
}
.SoftwareFeatureCard {
  width: 33%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-right: 12px;
}
.FeatureUpperSection {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  margin-bottom: 15px;
}
.FeatureUpperSection h1{
  width: 60%;
}
.FeatureIcon {
  width: 42px;
  height: 42px;
  background-color: var(--AccentGreen);
  color: var(--PrimaryGreen);
  margin-right: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .SoftwareFeaturesContainer {
    flex-direction: column;
  }
  .SoftwareFeatureCard {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (min-width: 1366px) {
  .SoftwareFeatureCard {
    padding-right: 60px;
  }
}

/* software animation styling section */
.SoftwareAnimationSection {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transform: translateY(-30px);
}

.AnimBlock {
  /* border: 3px solid orange; */
  width: 760px;
  height: 520px;
  position: relative;
  transform: translate(0px, 30px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.DesktopAnimation {
  width: 680px;
  height: 520px;
  position: absolute;
}
.MobileAnimation {
  width: 340px;
  height: 480px;
  position: absolute;
  right: 0;
  bottom: -30px;
}
.DecorativeRectangle {
  background-color: var(--AccentGreen);
  width: 800px;
  height: 180px;
  border-radius: 114px;
  position: absolute;
  bottom: 0;
}
/* @media (min-width: 1440px) {
  .SoftwareAnimationSection {
    transform: translateY(100px);
  }
} */
@media (max-width: 992px) {
  .MobileAnimation {
    display: none;
  }
  .DecorativeRectangle {
    width: 660px;
    height: 140px;
  }
  .DesktopAnimation {
    width: 520px;
    height: 460px;
  }
  .AnimBlock {
    transform: translate(0px, 80px);
  }
}
@media (max-width: 768px) {
  .DecorativeRectangle {
    width: 520px;
  }
}
@media (max-width: 620px) {
  .DecorativeRectangle {
    width: 380px;
  }
  .MobileAnimation {
    display: block;
    width: 470px;
    height: 580px;
    /* border: 3px solid black; */
    left: 50%;
    transform: translateX(-50%);
  }
  .DesktopAnimation {
    display: none;
  }
  .AnimBlock {
    transform: translate(10px, 70px);
  }
}
@media (max-width: 460px){
  .DecorativeRectangle{
    width: 320px;
  }
}

/* new website */
.benefitsWrapper {
  width: 100vw;
  max-width: 1366px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sectionPadding);
  /* margin: 40px 0px; */
}

.benefitsCardsWrapper {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  margin: 30px 0px;
}
.benefitCardRow {
  width: 50%;
  display: flex;
  justify-content: space-around;
}
.benefitCard {
  width: 48%;
  height: 206px;
  background-color: rgba(0, 255, 255, 0.356);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0px 50px;
}
.c1 {
  background-color: #d2fcfc;
}
.c2 {
  background-color: #fdf8e6;
}
.c3 {
  background-color: #e0f0e5;
}
.c4 {
  background-color: #d3e4fe;
}

/* modifying the default styles for specific use */
.benefitCard .LandingHeading {
  /* font-size: 34px; */
  margin: 0;
}
.benefitCard .universal-paragraphs {
  font-family: "Clash Display", sans-serif;
  font-weight: 500;
  margin: 0;
}
.c1 .LandingHeading,
.c1 .universal-paragraphs {
  color: #1ab8b8;
  transition: 0.3s cubic-bezier(0,.95,0,1)
}
.c2 .LandingHeading,
.c2 .universal-paragraphs {
  color: #ffcb00;
  transition: 0.3s cubic-bezier(0,.95,0,1)
}
.c3 .LandingHeading,
.c3 .universal-paragraphs {
  color: #62b47f;
  transition: 0.3s cubic-bezier(0,.95,0,1)
}
.c4 .LandingHeading,
.c4 .universal-paragraphs {
  color: #617cdd;
  transition: 0.3s cubic-bezier(0,.95,0,1)
}


.c1:hover .LandingHeading {
  transform: scale(0.96);
}
.c1:hover .universal-paragraphs {
  transform-origin: 50% 0%;
  transform: scale(0.96);
}

.c2:hover .LandingHeading {
  transform: scale(0.96);
}
.c2:hover .universal-paragraphs {
  transform-origin: 50% 0%;
  transform: scale(0.96);
}

.c3:hover .LandingHeading {
  transform: scale(0.96);
}
.c3:hover .universal-paragraphs {
  transform-origin: 50% 0%;
  transform: scale(0.96);
}

.c4:hover .LandingHeading {
  transform: scale(0.96);
}
.c4:hover .universal-paragraphs {
  transform-origin: 50% 0%;
  transform: scale(0.96);
}

@media (max-width: 992px) {
  .benefitsCardsWrapper {
    flex-direction: column;
  }
  .benefitCardRow {
    width: 100%;
    margin-bottom: 2vw;
  }
  .benefitCard {
    padding: 20px;
  }
}
@media (max-width: 540px) {
  .benefitCard {
    padding: 20px;
    height: 180px;
  }
}
@media (min-width: 1460px) {
  .benefitCard {
    max-width: 291px;
  }
}

/* GuestBenefitsWrapper */
.GuestBenefitsContentContainer {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--AccentGreen);
  display: flex;
  justify-content: center;
  align-items: center;
}
.GuestBenefitsWrapper {
  width: 100%;
  max-width: 1366px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sectionPadding);
  margin: 40px 0px;
}

.GuestBenefitsForm {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.GuestBenefitsForm input {
  width: 300px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: white;
}

@media (max-width: 540px) {
  .GuestBenefitsForm {
    display: flex;
    flex-direction: column;
  }
  .GuestBenefitsForm input {
    width: 100%;
  }
}

.PatentSectionParentContainer {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px 0px 0px 0px;
  background-color: #e9e9e9;
}
.PatentContentWrapper {
  width: 100%;
  max-width: 1366px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: var(--sectionPadding);
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
}
.PatentContentWrapper .universal-paragraphs {
  width: 80%;
}
.framesWrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 1366px;
  height: 200px;
  display: flex;
  position: relative;
  margin: 20px 0px;
  
}
.frame {
  /* position: absolute; */
  width: 98px;
  height: 120px;
  transition: 0.3s;
}
.f2,
.f4 {
  transform: translateY(30px);
}
.f1,
.f5 {
  transform: translateY(60px);
}
.f3:hover {
  transform: translateY(-3px);
}
.f2:hover {
  transform: translateY(27px);
}
.f4:hover {
  transform: translateY(27px);
}
.f1:hover {
  transform: translateY(57px);
}
.f5:hover {
  transform: translateY(57px);
}

@media (max-width: 920px) {
  .PatentContentWrapper {
    flex-direction: column-reverse;
  }
  .frame {
    width: 140px;
    height: 180px;
  }
  .framesWrapper {
    transform: translateY(-40px);
  }
  .PatentContentWrapper .universal-paragraphs {
    width: 100%;
  }
}

@media (max-width: 680px) {
  .PatentContentWrapper {
    width: 100%;
  }
  .frame {
    width: 126px;
    height: 160px;
  }
}

@media (max-width: 500px) {
  .PatentContentWrapper {
    width: 100%;
  }
  .frame {
    width: 90px;
    height: 120px;
  }
}

.LogosSwiper {
  width: 100%;
}

.SwiperCard {
  width: auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: saturate(0);
  mix-blend-mode: multiply;
  transition: 0.2s;

  /* border: 2px solid green; */
}
.SwiperCard img{
  height: 100%;
}
.SwiperCard:hover {
  filter: saturate(1);
}
.swiper-slide{
  display: flex;
  justify-content: space-evenly;
  /* width: fit-content !important; */
  /* border: 2px solid blue; */
}
.LogosSwiper .iaf{
  transform: translateX(-30px);
}


@media (max-width: 500px) {
  .SwiperCard {
    height: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .SwiperCard img{
    width: auto;
    height: 100%;
  }
  .LogosSwiper .iaf{
    transform: translateX(-20px);
  }
  
  
}

.ContactMasterContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  /* padding: 120px 0px; */
}
.ContactGetInTouchWrapper {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* background-color: orange; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.Getintouchcontainer {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sectionPadding);
  /* margin: 100px 0px; */
  position: relative;
  display: flex;
  max-width: 1366px;
}
.Getintouchcontainer div p {
  width: 100%;
}
.ContactLinks {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  max-width: 600px;
  transform: translateY(-12px);
}
.ContactLinks a{
  text-decoration: none;
}
.ContactDetailBox {
  width: 150px;
  height: 110px;
  background-color: grey;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: absolute;
  top: 90%;
  left: 20%;
  transform: translate(-50%, -50%);
}
.ContactLinks lord-icon {
  margin: 40px;
}

.box1 {
  background-color: var(--AccentGreen);
}
.box2 {
  background-color: #fdf8e6;
}
.box3 {
  background-color: #d3e4fe;
}
.box1 h1 {
  color: var(--PrimaryGreen);
}
.box2 h1 {
  color: #ffcb00;
}
.box3 h1 {
  color: #617cdd;
}
.ContactDetailBox h1 {
  transform: translateY(18px);
}

.decor {
  position: absolute;
  top: 0;
  z-index: -10;
}

@media (max-width: 1250px) {
  .Getintouchcontainer div p {
    width: 400px;
  }
  .ContactLinks {
    width: calc(100% - 400px);
  }
}

@media (max-width: 992px) {
  .Getintouchcontainer {
    flex-direction: column;
  }
  .Getintouchcontainer div p {
    width: 100%;
  }
  .ContactLinks {
    width: 100%;
    justify-content: space-around;
  }
}
@media (max-width: 500px) {
  .ContactDetailBox {
    width: 110px;
    height: 100px;
  }
}


.footer {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #363636;
  padding: 50px 0px 20px 0px;
  color: white;
  position: relative;
}
.FooterBase {
  width: 100%;
  max-width: 1366px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sectionPadding);
  display: flex;
  justify-content: space-between;
}

.FooterCompanyDetails {
  width: 45%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.FooterGrid {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 20px;
}

.FooterGrid div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0px 4px; /*For maintaining minimum distance*/
}
.FooterGrid div p {
  color: white;
  margin: 0;
}
.FooterGrid div h1 {
  color: white;
  margin: 0;
}

.div1 {
  grid-area: 1 / 1 / 3 / 3;
}
.div1 img {
  width: 80%;
  margin-bottom: 20px;
}
.div2 {
  grid-area: 3 / 1 / 4 / 3;
}
.div3 {
  grid-area: 4 / 1 / 5 / 3;
}
.div4 {
  grid-area: 5 / 1 / 6 / 3;
}
.div5 {
  grid-area: 6 / 1 / 7 / 3;
}
.div6 {
  grid-area: 7 / 1 / 8 / 3;
}
.div7 {
  grid-area: 3 / 3 / 4 / 4;
}
.div8 {
  grid-area: 3 / 4 / 4 / 5;
}
.div9 {
  grid-area: 3 / 5 / 4 / 6;
}
.div10 {
  grid-area: 4 / 3 / 5 / 6;
}
.div11 {
  grid-area: 5 / 3 / 6 / 6;
}
.div12 {
  grid-area: 6 / 3 / 7 / 6;
}
.div13 {
  grid-area: 7 / 3 / 8 / 4;
}
.div14 {
  grid-area: 7 / 4 / 8 / 5;
}
.div15 {
  grid-area: 7 / 5 / 8 / 6;
}

.socials {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  position: absolute;
  bottom: -80px;
}

.socials i {
  margin-right: 30px;
  transition: 0.1s;
}
.socials a{
  text-decoration: none;
}

#insta:hover {
  color: #ff41ad;
}
#linkedin:hover {
  color: #0e76a8;
}
#twitter:hover {
  color: #00acee;
}
#facebook:hover {
  color: #4267b2;
}
#Youtube:hover{
  color: red;
}
.RightsReserved{
  color: white;
  transform: scale(0.8);
  text-align: center;
}

@media (max-width:840px) {
  .footer{
    padding: 50px 0px 60px 0px;
  }
  .FooterBase{
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
  .FooterCompanyDetails{
    width: 100%;
  }
  .div1 img{
    display: none;
  }
  .socials{
    width: 100%;
    left: 50%;
    bottom: -60px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .RightsReserved{
    position: absolute;
    bottom: 0;
  }
}




/* querries form styling */
/* querries form styling */
/* querries form styling */
/* querries form styling */
.contactFormWrapper {
  width: 45%;
  max-width: 540px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.contactFormWrapper P {
  color: white;
}
.contactFormWrapper h1 {
  color: white;
}

.inputFeilds {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  margin-bottom: 14px;
}
.inputFeilds input {
  background-color: white;
}
.inputFeilds textarea {
  background-color: white;
}

.FormDiv1 {
  grid-area: 1 / 1 / 2 / 2;
}
.FormDiv2 {
  grid-area: 1 / 2 / 2 / 3;
}
.FormDiv3 {
  grid-area: 2 / 1 / 3 / 3;
}
.FormDiv4 {
  grid-area: 3 / 1 / 5 / 3;
}

@media (max-width: 840px) {
  .contactFormWrapper {
    width: 100%;
    padding: 6px;
    margin-bottom: 20px;
    max-width: 100%;
  }
}


.DemotilePArentContainer {
  /* background-color: var(--AccentGreen); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  margin: 90px 0px;
}
.demoTileContent {
  margin: 40px 0px;
  position: relative;
  width: 100%;
  max-width: 1366px;
}
.demoTileContent p {
  width: 40%;
}
.demoTileContent h1 {
  width: 40%;
}
.DemoPanel {
  position: absolute;
  right: 0%;
}
.DemoRobot {
  position: absolute;
  right: 0;
  z-index: 10;
}
.DemoRobot img{
  transform: scale(0.67);
}

@media (max-width: 1150px) {
  .DemoPanel{
    transform-origin: 100% 50%;
    transform: scale(0.9);
  }
  .DemoRobot img{
    transform: scale(0.61);
  }
}
@media (max-width: 992px) {
  .DemoPanel{
    transform-origin: 100% 50%;
    transform: scale(0.7);
  }
  .DemoRobot img{
    transform: scale(0.47);
  }
}
@media (max-width: 780px) {
  .DemoPanel{
    transform-origin: 100% 50%;
    transform: scale(0.6);
  }
  .DemoRobot img{
    transform: scale(0.4);
  }
}
@media (max-width: 695px) {
  .DemoPanel{
    transform-origin: 100% 50%;
    transform: scale(0.5);
  }
  .DemoRobot img{
    transform: scale(0.33);
  }
}
@media (max-width: 580px) {
  .demoTileContent p {
    width: 100%;
  }
  .demoTileContent h1 {
    width: 100%;
  }
  .DemoPanel{
   display: none;
  }
  .DemoRobot img{
   display: none;
  }
  .DemotilePArentContainer{
    margin: 0px 0px;
  }
}
.navbar-base {
  width: 100vw;
  height: 56px;
  position: fixed;
  top: 0;
  z-index: 20;
  /* transition: 0.3s; */
  display: flex;
  justify-content: center;
  /* background-color: white;   */
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06); */
}
.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: var(--sectionPadding);
  position: relative;
  /* transition: 0.3s; */
  max-width: 1366px;
}
.company-logo-container {
  width: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
}

.company-logo-container img {
  width: 90px;
  transition: 0.3s;
}

.OnHoverLogoAnimation{
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  transition: 0.3s;
}
.BackupLogo{
  display: none;
}

.company-logo-container:hover #desktopLogo{
  display: none;
}
.company-logo-container:hover .OnHoverLogoAnimation{
  display: block;
}
.company-logo-container:hover .BackupLogo{
  display: block;
}
.navbar_social_Container {
  width: 90px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.call {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 100px;
  background-color: black;
  background-color: rgba(0, 217, 255, 0.178);
  display: flex;
  justify-content: center;
  align-items: center;
}

.website-links {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  color: white;
}

.link-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  margin: 0px 10px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  transition: 0.3s;
  /* color: black; */
  position: relative;
}

.link-container:hover {
  font-weight: 800;
  color: var(--PrimaryGreen);
}
.active {
  font-weight: 800;
}
.SubLinkMenu {
  width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  /* border-radius: 6px; */
  position: absolute;
  top: 56px;
  left: 0;
  display: flex;
  flex-direction: column;
  display: none;
  animation: stretch;
  animation-duration: 0.3s;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.SubSubLinkMenu {
  width: 180px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 0px;
  left: -180px;
  display: flex;
  display: none;
  flex-direction: column;
  animation: stretch;
  animation-duration: 0.3s;
  z-index: 20;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
@keyframes stretch {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.SubLink {
  height: 50px;
  color: black;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid rgb(194, 194, 194);
  font-family: "Poppins", sans-serif;
  transition: 0.3s;
  position: relative;
}
.SubLink:hover {
  font-weight: 700;
  background-color: rgb(247, 247, 247);
  color: var(--PrimaryGreen) !important;
}

#SubNavAbout:hover #SubNavAboutOpen {
  display: block;
}
#SubNavSoftware:hover #SubNavSoftwareOpen {
  display: block;
}
#SubNavClients:hover #SubNavClientsOpen {
  display: block;
}
#SubNavContact:hover #SubNavContactOpen {
  display: block;
}
#SubNavSocial:hover .SubLinkMenu {
  display: block;
}
#SubNavSocial:hover #SubNavSocialOpen {
  display: block;
}
.hamburger-menu {
  display: none;
}

.NavSubLinksIcons {
  width: 24px;
  height: 24px;
  /* background-color: grey; */
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Media Querries Media Querries /* Media Querries Media Querries */ /* Media Querries Media Querries */
/* Media Querries Media Querries /* Media Querries Media Querries */ /* Media Querries Media Querries */
/* Media Querries Media Querries /* Media Querries Media Querries */ /* Media Querries Media Querries */
/* Media Querries Media Querries /* Media Querries Media Querries */ /* Media Querries Media Querries */

@media (max-width: 992px) {
  /* media querries for navbar ---------------------------------------------------------------------------------- */
  .company-logo-container img {
    width: 90px;
  }

  .hamburger-menu {
    display: block;
  }
  .website-links,
  .navbar_social_Container {
    display: none;
  }

  .hamMenu-items {
    width: 300px;
    height: 100%;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.877);
    -webkit-backdrop-filter: blur(9px);
            backdrop-filter: blur(9px);
    position: fixed;
    top: 0;
    z-index: 50;
    padding-top: 30px;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: scroll;
  }
  .hamMenu-items i {
    color: var(--headingColor);
    /* margin: 5vh; */
  }
  .hamMenu-items span {
    width: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 100px;
    margin-bottom: 2em;
  }
  .option {
    width: 100%;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px;

    color: var(--headingColor);
    margin: 0px 0px;
    text-decoration: none;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    animation: amit 0.3s linear;
  }
  @keyframes amit {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .DropdownBase {
    width: 100%;

    color: var(--headingColor);
    text-decoration: none;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;

    transition: 0.3s cubic-bezier(0.25, 0.24, 0.26, 0.99);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    /* border-left: 10px solid var(--AccentGreen); */
  }
  .callUsButtonWrapper{
    z-index: 20;
    position: absolute;
    bottom: 0%;
    width: 100%;
    padding-left: 30px;
    padding-top: 16px;
    background-color: white;
  }
}

.DetailedBenefitContentParentWrpper {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DetailedBenefitContentWrapper {
  width: 100%;
  max-width: 1366px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sectionPadding);
  margin: 80px 0px;
}

.chart{
  width: 70%;
  max-width: 1100px;
  margin-bottom: 100px;
}
.chart canvas{
  height: 500px;
}

.TakeDetailedInformation{
  display: flex;
  flex-wrap: wrap;
}
.TakeDetailedInformation .DefaultValueContainer input{
  width: 300px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.DefaultValueContainer{
  display: flex;
  flex-direction: column;
}
.DefaultValueContainer span{
  margin: 0;
  padding-left: 16px;
  transform: translateY(-10px);
  font-size: 11px;
  color: grey;
  font-weight: 400;
  opacity: 0.8;
}
@media (max-width:640px) {
  .DefaultValueContainer{
    width: 100%;
  }
  .TakeDetailedInformation .DefaultValueContainer input{
    width: 100%;
  }
  .chart{
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 20px;
  }
  .chart canvas{
    height: 400px;
  }
}


.DetailedAboutWrapper {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AboutLandingBanner {
  width: 100%;
  height: 450px;
  filter: brightness(0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--sectionPadding);
}
.AboutLandingBanner img {
  width: 160px;
  z-index: 10;
}
@media (max-width: 768px) {
  .AboutLandingBanner {
    height: 360px;
  }
}

/* company details */
.DetailedAboutCompany {
  width: 70%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 100px 0px;
  text-align: center;
  padding: var(--sectionPadding);
}
.DetailedAboutCompany h1 {
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .DetailedAboutCompany {
    width: 100%;
    margin: 60px;
  }
}

/* mission vision values */
.container {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px 5vw;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid red; */
}
.vision {
  width: 26%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px 20px;
}
.microAnimations {
  width: 70px;
  height: 70px;
  /* height: 100px; */
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .vision {
    width: 50%;
  }
  .microAnimations {
    width: 30%;
  }
}

@media screen and (max-width: 750px) {
  .container {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .vision {
    border: none;
    width: 70%;
  }
  .microAnimations {
    width: 20%;
  }
}

@media (max-width: 750px) and (max-height: 600px) and (orientation: landscape) {
  .microAnimations {
    display: none;
  }
}

@media (max-width: 550px) and (orientation: portrait) {
  .vision {
    width: 80%;
  }
}

/* certification decoration card styling */
.CertificationDecorationCard {
  width: 100vw;
  height: 226px;
  background-color: #fff6c7;
  margin: 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.CertificationContentcontainer {
  width: 100%;
  max-width: 1366px;
  padding: var(--sectionPadding);
}
.CertificationContentcontainer p {
  width: 60%;
}
.ISOLogoParallax {
  width: 100px;
  height: 50px;
  position: absolute;
  right: 100px;
  top: 80px;
  opacity: 0.7;
}
.CELogoParallax {
  width: 90px;
  height: 30px;
  position: absolute;
  right: 260px;
  top: 80px;
  mix-blend-mode: multiply;
  opacity: 0.7;
}
.ISOLogoParallax img {
  width: 100%;
}
.CELogoParallax img {
  width: 100%;
}
@media (max-width: 992px) {
  .ISOLogoParallax,
  .CELogoParallax {
    display: none;
  }
  .CertificationContentcontainer {
    width: 100%;
  }
  .CertificationContentcontainer p {
    width: 100%;
  }
  .CertificationDecorationCard {
    padding: 40px 60px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media (max-width: 620px) {
  .CertificationDecorationCard {
    padding: 40px 30px;
  }
}

/* team section css */
.teamSection {
  width: 70%;
  max-width: 900px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teamSection p {
  width: 90%;
}
.teamMembersWrapper {
  width: 100%;
  max-width: 900px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.myCard {
  background-color: transparent;
  width: 150px;
  height: 170px;
  perspective: 1000px;
  margin: 10px 20px;
  /* background-color: aqua;   */
  overflow: hidden;
}

.innerCard {
  position: relative;
  width: 100%;
  height: 200px;
  /* background-color: #fff6c7; */
  text-align: center;
  transition: 0.5s;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.memberDesignation {
  transition: 0.5s;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.MemberDP {
  width: 72px;
  transition: 0.5s;
  height: 72px;
  /* background-color: #f1aa00; */
  border-radius: 100px;
  margin-bottom: 10px;
  border: 3px solid #f1aa00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MemberDP img {
  width: 90%;
}
.innerCard h1 {
  transition: 0.5s;
}

.MemberSocialLinks {
  transition: 0.5s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 24px;
  display: flex;
  justify-content: space-between;
  transform: translateY(60px);
}
.myCard:hover .MemberSocialLinks {
  transform: translateY(-40px);
}
.myCard:hover .innerCard h1 {
  transform: translateY(-50px);
}
.myCard:hover .innerCard p {
  transform: translateY(-50px);
}
.myCard:hover .MemberDP {
  transform: translateY(-80px);
  opacity: 0;
}
.socialBox {
  width: 24px;
  height: 24px;
  margin: 0px 4px;
  /* background-color: grey; */
  border-radius: 3px;
}

@media (max-width: 992px) {
  .teamSection {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .myCard {
    width: 140px;
    height: 160px;
  }
}

.DDCustomStyling {
  margin-top: 56px;
  position: relative;
  overflow: hidden;
  height: 464px;
}
.DDinnerCustomStyling h1 {
  text-transform: uppercase;
}
.DDinnerCustomStyling p {
  width: 500px;
}
@media (max-width: 720px) {
  .DDCustomStyling {
    height: 600px;
  }
  .DDinnerCustomStyling {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .DDinnerCustomStyling p {
    width: 90%;
  }
}

.FeatureBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
}
.FeatureBanner h1 {
  color: white;
  z-index: 1;
}
.FeatureBanner p {
  color: white;
  width: 700px;
  margin: 0;
  z-index: 1;
}
@media (max-width: 720px) {
  .FeatureBanner {
    height: 400px;
    align-items: center;
    text-align: center;
  }
  .FeatureBanner p {
    width: 90%;
  }
}

.MAsection {
  background-color: var(--AccentGreen);
  padding: 70px 0px;
  position: relative;
  overflow: hidden;
}
.MAsection p {
  margin: 0;
  width: 60%;
}
.MAsection h1 {
  width: 50%;
}
.MAsection .ma {
  position: absolute;
  width: 260px;
  right: 100px;
  top: -220px;
}
.MAsection .UniversalInnerContainer{
  position: relative;
}
@media (max-width: 760px) {
  .MAsection p {
    width: 90%;
  }
  .MAsection h1 {
    width: 90%;
  }
  .MAsection .ma {
    display: none;
  }
}

/* @media (max-width: 520px) {
 .DDCustomStyling{
  background-position-x: right !important;
 }
} */

.PUD {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ClientsLandingBanner {
  margin-top: 56px;
  width: 100%;
  height: 400px;
  position: relative;
  text-align: center;
}
.ClientsLandingBanner h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.ClientsTestimonialsWrapper {
  display: flex;
}
.SectionHeadingandDesc {
  width: 300px;
  min-width: 300px;
  transform: translateY(40px);
}
.SectionHeadingandDesc img {
  margin-bottom: 20px;
}

.ClientsestimonialCardsSwiper {
  width: 100%;
  height: 350px;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin-left: 40px;
}

.ClientTestimonialCard {
  width: 450px;
  /* min-width: 400px; */
  /* max-width: 500px; */
  height: 314px;
  /* border: 2px solid red; */
  border-radius: 10px;
  background: #fff;
  margin: 80px 0px 40px 2px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 26px 20px 26px;
}
.ClientTestimonialCard p {
  color: grey;
}
.testimonialCompanyLogo {
  width: 76px;
  height: 76px;
  border-radius: 100px;
  position: absolute;
  top: -38px;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonialCompanyLogo img {
  width: 60px;
}
.ONGC {
  background-color: #fff9f9;
}
.BROOK {
  background-color: #f2faff;
}
.Welspun {
  background-color: #eef9fd;
}
.SolarPulse {
  background-color: #fff8ef;
}
#ongc {
  background-color: #ffe4e3;
}
#brookfield {
  background-color: #cceaff;
}
#welspun {
  background-color: #c8f5ff;
}
#solarpulse {
  background-color: #fff2de;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: -46%;
  width: 100%;
}

.swiper-pagination-bullet {
  background: var(--PrimaryGreen);
}

@media (max-width: 992px) {
  .ClientsestimonialCardsSwiper {
    margin-left: 0px;
  }
  .ClientsTestimonialsWrapper {
    flex-direction: column;
  }
  .SectionHeadingandDesc {
    width: 100%;
    margin-bottom: 10px;
    transform: translateY(0px);
  }
  .ClientTestimonialCard {
    width: 100% !important;
    /* min-width: 100% !important; */
    /* max-width: 100% !important; */
    margin: 100px 0px;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 60px;
    left: -40%;
    width: 100%;
  }
}

@media (max-width: 540px) {
  .ClientTestimonialCard {
    width: 100% !important;
    /* min-width: 90% !important; */
    /* max-width: 100% !important; */
    margin: 100px 0px;
    height: 340px;
  }
  .ClientsestimonialCardsSwiper {
    height: 390px;
  }
  .ClientsTestimonialSwiperParent {
    width: 100%;
  }
  .swiper-container {
    overflow: visible !important;
  }
}

.ArticleCardSpecifiedCss {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12) !important;
  height: 340px !important;
  transition: 0.3s !important;
}

#edtimes {
  background-color: #cccbff;
}
#dainik {
  background-color: #fff8b5;
}

/* news cutout css */
.newsCard {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  /* border: 2px solid red; */
  border-radius: 20px;
  background: #854141;
  margin: 00px 0px 0px 2px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  filter: brightness(0.8);
  transition: 0.14s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.newsCard:hover {
  border-radius: 30px;
  scale: 0.98;
  rotate: 1deg;
}
@media (max-width: 992px) {
  .AdditionalCssForNewsCutouts {
    height: 240px;
  }
}



/* Patent swiper css here  */
.PatentSwiperAdditionalCss{
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.PatentCard {
  width: 178px;
  min-width: 178px;
  max-width: 178px;
  height: 240px;
  /* border: 2px solid red; */
  /* border-radius: 20px; */
  background: #854141;
  margin: 00px 0px 0px 2px;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.14s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
@media (max-width: 992px) {
  .PatentSwiperAdditionalCss{
    height: 260px;
  }
}

