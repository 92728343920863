.DDCustomStyling {
  margin-top: 56px;
  position: relative;
  overflow: hidden;
  height: 464px;
}
.DDinnerCustomStyling h1 {
  text-transform: uppercase;
}
.DDinnerCustomStyling p {
  width: 500px;
}
@media (max-width: 720px) {
  .DDCustomStyling {
    height: 600px;
  }
  .DDinnerCustomStyling {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .DDinnerCustomStyling p {
    width: 90%;
  }
}

.FeatureBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
}
.FeatureBanner h1 {
  color: white;
  z-index: 1;
}
.FeatureBanner p {
  color: white;
  width: 700px;
  margin: 0;
  z-index: 1;
}
@media (max-width: 720px) {
  .FeatureBanner {
    height: 400px;
    align-items: center;
    text-align: center;
  }
  .FeatureBanner p {
    width: 90%;
  }
}

.MAsection {
  background-color: var(--AccentGreen);
  padding: 70px 0px;
  position: relative;
  overflow: hidden;
}
.MAsection p {
  margin: 0;
  width: 60%;
}
.MAsection h1 {
  width: 50%;
}
.MAsection .ma {
  position: absolute;
  width: 260px;
  right: 100px;
  top: -220px;
}
.MAsection .UniversalInnerContainer{
  position: relative;
}
@media (max-width: 760px) {
  .MAsection p {
    width: 90%;
  }
  .MAsection h1 {
    width: 90%;
  }
  .MAsection .ma {
    display: none;
  }
}

/* @media (max-width: 520px) {
 .DDCustomStyling{
  background-position-x: right !important;
 }
} */
