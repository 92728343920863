.DemotilePArentContainer {
  /* background-color: var(--AccentGreen); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  margin: 90px 0px;
}
.demoTileContent {
  margin: 40px 0px;
  position: relative;
  width: 100%;
  max-width: 1366px;
}
.demoTileContent p {
  width: 40%;
}
.demoTileContent h1 {
  width: 40%;
}
.DemoPanel {
  position: absolute;
  right: 0%;
}
.DemoRobot {
  position: absolute;
  right: 0;
  z-index: 10;
}
.DemoRobot img{
  transform: scale(0.67);
}

@media (max-width: 1150px) {
  .DemoPanel{
    transform-origin: 100% 50%;
    transform: scale(0.9);
  }
  .DemoRobot img{
    transform: scale(0.61);
  }
}
@media (max-width: 992px) {
  .DemoPanel{
    transform-origin: 100% 50%;
    transform: scale(0.7);
  }
  .DemoRobot img{
    transform: scale(0.47);
  }
}
@media (max-width: 780px) {
  .DemoPanel{
    transform-origin: 100% 50%;
    transform: scale(0.6);
  }
  .DemoRobot img{
    transform: scale(0.4);
  }
}
@media (max-width: 695px) {
  .DemoPanel{
    transform-origin: 100% 50%;
    transform: scale(0.5);
  }
  .DemoRobot img{
    transform: scale(0.33);
  }
}
@media (max-width: 580px) {
  .demoTileContent p {
    width: 100%;
  }
  .demoTileContent h1 {
    width: 100%;
  }
  .DemoPanel{
   display: none;
  }
  .DemoRobot img{
   display: none;
  }
  .DemotilePArentContainer{
    margin: 0px 0px;
  }
}