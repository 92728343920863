.navbar-base {
  width: 100vw;
  height: 56px;
  position: fixed;
  top: 0;
  z-index: 20;
  /* transition: 0.3s; */
  display: flex;
  justify-content: center;
  /* background-color: white;   */
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06); */
}
.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: var(--sectionPadding);
  position: relative;
  /* transition: 0.3s; */
  max-width: 1366px;
}
.company-logo-container {
  width: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
}

.company-logo-container img {
  width: 90px;
  transition: 0.3s;
}

.OnHoverLogoAnimation{
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  transition: 0.3s;
}
.BackupLogo{
  display: none;
}

.company-logo-container:hover #desktopLogo{
  display: none;
}
.company-logo-container:hover .OnHoverLogoAnimation{
  display: block;
}
.company-logo-container:hover .BackupLogo{
  display: block;
}
.navbar_social_Container {
  width: 90px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.call {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 100px;
  background-color: black;
  background-color: rgba(0, 217, 255, 0.178);
  display: flex;
  justify-content: center;
  align-items: center;
}

.website-links {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  color: white;
}

.link-container {
  width: fit-content;
  font-size: 14px;
  margin: 0px 10px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  transition: 0.3s;
  /* color: black; */
  position: relative;
}

.link-container:hover {
  font-weight: 800;
  color: var(--PrimaryGreen);
}
.active {
  font-weight: 800;
}
.SubLinkMenu {
  width: 200px;
  height: fit-content;
  background-color: white;
  /* border-radius: 6px; */
  position: absolute;
  top: 56px;
  left: 0;
  display: flex;
  flex-direction: column;
  display: none;
  animation: stretch;
  animation-duration: 0.3s;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.SubSubLinkMenu {
  width: 180px;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 0px;
  left: -180px;
  display: flex;
  display: none;
  flex-direction: column;
  animation: stretch;
  animation-duration: 0.3s;
  z-index: 20;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
@keyframes stretch {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.SubLink {
  height: 50px;
  color: black;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid rgb(194, 194, 194);
  font-family: "Poppins", sans-serif;
  transition: 0.3s;
  position: relative;
}
.SubLink:hover {
  font-weight: 700;
  background-color: rgb(247, 247, 247);
  color: var(--PrimaryGreen) !important;
}

#SubNavAbout:hover #SubNavAboutOpen {
  display: block;
}
#SubNavSoftware:hover #SubNavSoftwareOpen {
  display: block;
}
#SubNavClients:hover #SubNavClientsOpen {
  display: block;
}
#SubNavContact:hover #SubNavContactOpen {
  display: block;
}
#SubNavSocial:hover .SubLinkMenu {
  display: block;
}
#SubNavSocial:hover #SubNavSocialOpen {
  display: block;
}
.hamburger-menu {
  display: none;
}

.NavSubLinksIcons {
  width: 24px;
  height: 24px;
  /* background-color: grey; */
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Media Querries Media Querries /* Media Querries Media Querries */ /* Media Querries Media Querries */
/* Media Querries Media Querries /* Media Querries Media Querries */ /* Media Querries Media Querries */
/* Media Querries Media Querries /* Media Querries Media Querries */ /* Media Querries Media Querries */
/* Media Querries Media Querries /* Media Querries Media Querries */ /* Media Querries Media Querries */

@media (max-width: 992px) {
  /* media querries for navbar ---------------------------------------------------------------------------------- */
  .company-logo-container img {
    width: 90px;
  }

  .hamburger-menu {
    display: block;
  }
  .website-links,
  .navbar_social_Container {
    display: none;
  }

  .hamMenu-items {
    width: 300px;
    height: 100%;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.877);
    backdrop-filter: blur(9px);
    position: fixed;
    top: 0;
    z-index: 50;
    padding-top: 30px;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: scroll;
  }
  .hamMenu-items i {
    color: var(--headingColor);
    /* margin: 5vh; */
  }
  .hamMenu-items span {
    width: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 100px;
    margin-bottom: 2em;
  }
  .option {
    width: 100%;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px;

    color: var(--headingColor);
    margin: 0px 0px;
    text-decoration: none;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    animation: amit 0.3s linear;
  }
  @keyframes amit {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .DropdownBase {
    width: 100%;

    color: var(--headingColor);
    text-decoration: none;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;

    transition: 0.3s cubic-bezier(0.25, 0.24, 0.26, 0.99);
    user-select: none;
    cursor: pointer;
    /* border-left: 10px solid var(--AccentGreen); */
  }
  .callUsButtonWrapper{
    z-index: 20;
    position: absolute;
    bottom: 0%;
    width: 100%;
    padding-left: 30px;
    padding-top: 16px;
    background-color: white;
  }
}
