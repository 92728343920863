.home {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: white;
  width: 100vw;
  margin-top: 100px;
}

.homeContainerCustomCss {
  max-width: 1366px;
  height: fit-content;
  display: flex;
  padding: var(--sectionPadding);
  justify-content: flex-start;
}
/* negleting padding in bigger screens */
@media (min-width: 1366px) {
  .homeContainerCustomCss {
    padding: 0;
  }
}
.box {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: fit-content;
  padding-right: 100px;
}
.box p {
  margin: 0;
}
.ShiftUp {
  transform: translateY(-26px);
}

@media screen and (max-width: 992px) {
  .home {
    height: fit-content;
    padding: 0vw 0px;
  }
  .homeContainerCustomCss {
    flex-direction: column;
    height: fit-content;
  }
  .box {
    width: 100%;
    padding: 0;
  }
  .ShiftUp {
    transform: translateY(0px);
    margin-top: 80px;
    text-align: right;
    align-items: flex-end;
  }
}

@media (max-width: 540px) {
}
