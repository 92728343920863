.product_information_wrapper {
  width: 100vw;
  height: fit-content;
  padding: var(--sectionPadding);
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 120px 0px; */
}

.maxWidthContainer {
  max-width: 1366px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content_container {
  width: 50%;
  padding-right: 180px;
}
.grid_container {
  width: 50%;
}

@media (max-width: 992px) {
  .maxWidthContainer {
    flex-direction: column-reverse;
  }
  .content_container,
  .grid_container {
    width: 100%;
  }
  .content_container {
    padding: 0;
    margin-top: 60px;
  }
}

.GridParent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  width: 100%;
  height: 450px;
  max-width: 550px;
  /* min-width: 500px; */
}
@media (min-width: 1366px) {
  .GridParent {
    height: 500px;
    max-width: 650px;
  }
}
@media (max-width: 540px) {
  .GridParent {
    height: 290px;
    grid-column-gap: 6px;
    grid-row-gap: 6px;
  }
}

.one {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 1 / 1 / 3 / 3;

  display: flex;
  justify-content: center;
  align-items: center;
}
.one video{
  width: 100%;
  transform: scale(2);
}
.two {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 1 / 4 / 2 / 5;
  visibility: hidden;
}
.three {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 2 / 4 / 3 / 5;
}
.four {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 1 / 3 / 3 / 4;
}
.five {
  border-radius: 10px;
  /* background-color: grey; */
  grid-area: 3 / 3 / 4 / 5;
}
.five video{
width: 100%;
}
.six {
  border-radius: 10px;
  background-color: var(--PrimaryGreen);
  grid-area: 3 / 1 / 4 / 3;
  padding: 16px;
  color: white;
  position: relative;
}
.six h1{
  color: white;
}
.icon{
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}