.DetailedBenefitContentParentWrpper {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DetailedBenefitContentWrapper {
  width: 100%;
  max-width: 1366px;
  height: fit-content;
  padding: var(--sectionPadding);
  margin: 80px 0px;
}

.chart{
  width: 70%;
  max-width: 1100px;
  margin-bottom: 100px;
}
.chart canvas{
  height: 500px;
}

.TakeDetailedInformation{
  display: flex;
  flex-wrap: wrap;
}
.TakeDetailedInformation .DefaultValueContainer input{
  width: 300px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.DefaultValueContainer{
  display: flex;
  flex-direction: column;
}
.DefaultValueContainer span{
  margin: 0;
  padding-left: 16px;
  transform: translateY(-10px);
  font-size: 11px;
  color: grey;
  font-weight: 400;
  opacity: 0.8;
}
@media (max-width:640px) {
  .DefaultValueContainer{
    width: 100%;
  }
  .TakeDetailedInformation .DefaultValueContainer input{
    width: 100%;
  }
  .chart{
    width: 90%;
    height: fit-content;
    margin-bottom: 20px;
  }
  .chart canvas{
    height: 400px;
  }
}

