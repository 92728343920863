.first_page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* background-color: black; */
}

.first_page video {
  width: 100vw;
  /* height: 100%; */
  opacity: 1;
  z-index: 19;
  filter: brightness(0.7);
  animation: fade 0.5s linear;
}
@keyframes fade {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
.text_wrapper {
  width: 1100px;
  max-width: 1200px;
  height: fit-content;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 19;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text_wrapper p {
  width: 70%;
}

@media (max-width: 992px) {
  .first_page {
    background-size: cover;
    background-position-x: 100%;
  }
  .first_page video {
    width: auto;
    height: 100%;
    /* opacity: 0.7; */
    filter: brightness(0.7);
  }

  .text_wrapper {
    width: 94%;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .text_wrapper p {
    width: 90%;
  }
}

@media screen and (max-width: 550px) {
  .first_page {
    background-size: cover;
    background-position-x: 90%;
  }
  .first_page video {
    width: auto;
    height: 100%;
    /* opacity: 0.7; */
    filter: brightness(0.7);
    transform: translateX(-500px);
  }
}
