.ContactMasterContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  /* padding: 120px 0px; */
}
.ContactGetInTouchWrapper {
  width: 100%;
  height: fit-content;
  /* background-color: orange; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.Getintouchcontainer {
  width: 100%;
  height: fit-content;
  padding: var(--sectionPadding);
  /* margin: 100px 0px; */
  position: relative;
  display: flex;
  max-width: 1366px;
}
.Getintouchcontainer div p {
  width: 100%;
}
.ContactLinks {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  max-width: 600px;
  transform: translateY(-12px);
}
.ContactLinks a{
  text-decoration: none;
}
.ContactDetailBox {
  width: 150px;
  height: 110px;
  background-color: grey;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: absolute;
  top: 90%;
  left: 20%;
  transform: translate(-50%, -50%);
}
.ContactLinks lord-icon {
  margin: 40px;
}

.box1 {
  background-color: var(--AccentGreen);
}
.box2 {
  background-color: #fdf8e6;
}
.box3 {
  background-color: #d3e4fe;
}
.box1 h1 {
  color: var(--PrimaryGreen);
}
.box2 h1 {
  color: #ffcb00;
}
.box3 h1 {
  color: #617cdd;
}
.ContactDetailBox h1 {
  transform: translateY(18px);
}

.decor {
  position: absolute;
  top: 0;
  z-index: -10;
}

@media (max-width: 1250px) {
  .Getintouchcontainer div p {
    width: 400px;
  }
  .ContactLinks {
    width: calc(100% - 400px);
  }
}

@media (max-width: 992px) {
  .Getintouchcontainer {
    flex-direction: column;
  }
  .Getintouchcontainer div p {
    width: 100%;
  }
  .ContactLinks {
    width: 100%;
    justify-content: space-around;
  }
}
@media (max-width: 500px) {
  .ContactDetailBox {
    width: 110px;
    height: 100px;
  }
}

