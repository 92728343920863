.PUD {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ClientsLandingBanner {
  margin-top: 56px;
  width: 100%;
  height: 400px;
  position: relative;
  text-align: center;
}
.ClientsLandingBanner h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.ClientsTestimonialsWrapper {
  display: flex;
}
.SectionHeadingandDesc {
  width: 300px;
  min-width: 300px;
  transform: translateY(40px);
}
.SectionHeadingandDesc img {
  margin-bottom: 20px;
}

.ClientsestimonialCardsSwiper {
  width: 100%;
  height: 350px;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin-left: 40px;
}

.ClientTestimonialCard {
  width: 450px;
  /* min-width: 400px; */
  /* max-width: 500px; */
  height: 314px;
  /* border: 2px solid red; */
  border-radius: 10px;
  background: #fff;
  margin: 80px 0px 40px 2px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 26px 20px 26px;
}
.ClientTestimonialCard p {
  color: grey;
}
.testimonialCompanyLogo {
  width: 76px;
  height: 76px;
  border-radius: 100px;
  position: absolute;
  top: -38px;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonialCompanyLogo img {
  width: 60px;
}
.ONGC {
  background-color: #fff9f9;
}
.BROOK {
  background-color: #f2faff;
}
.Welspun {
  background-color: #eef9fd;
}
.SolarPulse {
  background-color: #fff8ef;
}
#ongc {
  background-color: #ffe4e3;
}
#brookfield {
  background-color: #cceaff;
}
#welspun {
  background-color: #c8f5ff;
}
#solarpulse {
  background-color: #fff2de;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: -46%;
  width: 100%;
}

.swiper-pagination-bullet {
  background: var(--PrimaryGreen);
}

@media (max-width: 992px) {
  .ClientsestimonialCardsSwiper {
    margin-left: 0px;
  }
  .ClientsTestimonialsWrapper {
    flex-direction: column;
  }
  .SectionHeadingandDesc {
    width: 100%;
    margin-bottom: 10px;
    transform: translateY(0px);
  }
  .ClientTestimonialCard {
    width: 100% !important;
    /* min-width: 100% !important; */
    /* max-width: 100% !important; */
    margin: 100px 0px;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 60px;
    left: -40%;
    width: 100%;
  }
}

@media (max-width: 540px) {
  .ClientTestimonialCard {
    width: 100% !important;
    /* min-width: 90% !important; */
    /* max-width: 100% !important; */
    margin: 100px 0px;
    height: 340px;
  }
  .ClientsestimonialCardsSwiper {
    height: 390px;
  }
  .ClientsTestimonialSwiperParent {
    width: 100%;
  }
  .swiper-container {
    overflow: visible !important;
  }
}

.ArticleCardSpecifiedCss {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12) !important;
  height: 340px !important;
  transition: 0.3s !important;
}

#edtimes {
  background-color: #cccbff;
}
#dainik {
  background-color: #fff8b5;
}

/* news cutout css */
.newsCard {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  /* border: 2px solid red; */
  border-radius: 20px;
  background: #854141;
  margin: 00px 0px 0px 2px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  filter: brightness(0.8);
  transition: 0.14s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.newsCard:hover {
  border-radius: 30px;
  scale: 0.98;
  rotate: 1deg;
}
@media (max-width: 992px) {
  .AdditionalCssForNewsCutouts {
    height: 240px;
  }
}



/* Patent swiper css here  */
.PatentSwiperAdditionalCss{
  width: fit-content !important;
}
.PatentCard {
  width: 178px;
  min-width: 178px;
  max-width: 178px;
  height: 240px;
  /* border: 2px solid red; */
  /* border-radius: 20px; */
  background: #854141;
  margin: 00px 0px 0px 2px;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.14s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
@media (max-width: 992px) {
  .PatentSwiperAdditionalCss{
    height: 260px;
  }
}
