.PatentSectionParentContainer {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px 0px 0px 0px;
  background-color: #e9e9e9;
}
.PatentContentWrapper {
  width: 100%;
  max-width: 1366px;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: var(--sectionPadding);
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
}
.PatentContentWrapper .universal-paragraphs {
  width: 80%;
}
.framesWrapper {
  width: fit-content;
  max-width: 1366px;
  height: 200px;
  display: flex;
  position: relative;
  margin: 20px 0px;
  
}
.frame {
  /* position: absolute; */
  width: 98px;
  height: 120px;
  transition: 0.3s;
}
.f2,
.f4 {
  transform: translateY(30px);
}
.f1,
.f5 {
  transform: translateY(60px);
}
.f3:hover {
  transform: translateY(-3px);
}
.f2:hover {
  transform: translateY(27px);
}
.f4:hover {
  transform: translateY(27px);
}
.f1:hover {
  transform: translateY(57px);
}
.f5:hover {
  transform: translateY(57px);
}

@media (max-width: 920px) {
  .PatentContentWrapper {
    flex-direction: column-reverse;
  }
  .frame {
    width: 140px;
    height: 180px;
  }
  .framesWrapper {
    transform: translateY(-40px);
  }
  .PatentContentWrapper .universal-paragraphs {
    width: 100%;
  }
}

@media (max-width: 680px) {
  .PatentContentWrapper {
    width: 100%;
  }
  .frame {
    width: 126px;
    height: 160px;
  }
}

@media (max-width: 500px) {
  .PatentContentWrapper {
    width: 100%;
  }
  .frame {
    width: 90px;
    height: 120px;
  }
}

.LogosSwiper {
  width: 100%;
}

.SwiperCard {
  width: auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: saturate(0);
  mix-blend-mode: multiply;
  transition: 0.2s;

  /* border: 2px solid green; */
}
.SwiperCard img{
  height: 100%;
}
.SwiperCard:hover {
  filter: saturate(1);
}
.swiper-slide{
  display: flex;
  justify-content: space-evenly;
  /* width: fit-content !important; */
  /* border: 2px solid blue; */
}
.LogosSwiper .iaf{
  transform: translateX(-30px);
}


@media (max-width: 500px) {
  .SwiperCard {
    height: 30px;
    width: fit-content;
  }
  .SwiperCard img{
    width: auto;
    height: 100%;
  }
  .LogosSwiper .iaf{
    transform: translateX(-20px);
  }
  
  
}
