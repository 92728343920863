.DetailedAboutWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AboutLandingBanner {
  width: 100%;
  height: 450px;
  filter: brightness(0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--sectionPadding);
}
.AboutLandingBanner img {
  width: 160px;
  z-index: 10;
}
@media (max-width: 768px) {
  .AboutLandingBanner {
    height: 360px;
  }
}

/* company details */
.DetailedAboutCompany {
  width: 70%;
  height: fit-content;
  margin: 100px 0px;
  text-align: center;
  padding: var(--sectionPadding);
}
.DetailedAboutCompany h1 {
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .DetailedAboutCompany {
    width: 100%;
    margin: 60px;
  }
}

/* mission vision values */
.container {
  display: flex;
  height: fit-content;
  padding: 0px 5vw;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid red; */
}
.vision {
  width: 26%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px 20px;
}
.microAnimations {
  width: 70px;
  height: 70px;
  /* height: 100px; */
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .vision {
    width: 50%;
  }
  .microAnimations {
    width: 30%;
  }
}

@media screen and (max-width: 750px) {
  .container {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .vision {
    border: none;
    width: 70%;
  }
  .microAnimations {
    width: 20%;
  }
}

@media (max-width: 750px) and (max-height: 600px) and (orientation: landscape) {
  .microAnimations {
    display: none;
  }
}

@media (max-width: 550px) and (orientation: portrait) {
  .vision {
    width: 80%;
  }
}

/* certification decoration card styling */
.CertificationDecorationCard {
  width: 100vw;
  height: 226px;
  background-color: #fff6c7;
  margin: 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.CertificationContentcontainer {
  width: 100%;
  max-width: 1366px;
  padding: var(--sectionPadding);
}
.CertificationContentcontainer p {
  width: 60%;
}
.ISOLogoParallax {
  width: 100px;
  height: 50px;
  position: absolute;
  right: 100px;
  top: 80px;
  opacity: 0.7;
}
.CELogoParallax {
  width: 90px;
  height: 30px;
  position: absolute;
  right: 260px;
  top: 80px;
  mix-blend-mode: multiply;
  opacity: 0.7;
}
.ISOLogoParallax img {
  width: 100%;
}
.CELogoParallax img {
  width: 100%;
}
@media (max-width: 992px) {
  .ISOLogoParallax,
  .CELogoParallax {
    display: none;
  }
  .CertificationContentcontainer {
    width: 100%;
  }
  .CertificationContentcontainer p {
    width: 100%;
  }
  .CertificationDecorationCard {
    padding: 40px 60px;
    height: fit-content;
  }
}

@media (max-width: 620px) {
  .CertificationDecorationCard {
    padding: 40px 30px;
  }
}

/* team section css */
.teamSection {
  width: 70%;
  max-width: 900px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teamSection p {
  width: 90%;
}
.teamMembersWrapper {
  width: 100%;
  max-width: 900px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.myCard {
  background-color: transparent;
  width: 150px;
  height: 170px;
  perspective: 1000px;
  margin: 10px 20px;
  /* background-color: aqua;   */
  overflow: hidden;
}

.innerCard {
  position: relative;
  width: 100%;
  height: 200px;
  /* background-color: #fff6c7; */
  text-align: center;
  transition: 0.5s;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.memberDesignation {
  transition: 0.5s;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
}
.MemberDP {
  width: 72px;
  transition: 0.5s;
  height: 72px;
  /* background-color: #f1aa00; */
  border-radius: 100px;
  margin-bottom: 10px;
  border: 3px solid #f1aa00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MemberDP img {
  width: 90%;
}
.innerCard h1 {
  transition: 0.5s;
}

.MemberSocialLinks {
  transition: 0.5s;
  width: fit-content;
  height: 24px;
  display: flex;
  justify-content: space-between;
  transform: translateY(60px);
}
.myCard:hover .MemberSocialLinks {
  transform: translateY(-40px);
}
.myCard:hover .innerCard h1 {
  transform: translateY(-50px);
}
.myCard:hover .innerCard p {
  transform: translateY(-50px);
}
.myCard:hover .MemberDP {
  transform: translateY(-80px);
  opacity: 0;
}
.socialBox {
  width: 24px;
  height: 24px;
  margin: 0px 4px;
  /* background-color: grey; */
  border-radius: 3px;
}

@media (max-width: 992px) {
  .teamSection {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .myCard {
    width: 140px;
    height: 160px;
  }
}
