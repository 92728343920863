/* paragraph font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  /* colours */
  --dark: #181a20;
  --light: #1f2127;
  --AccentGreen: #bbeecb;
  --PrimaryGreen: #0c9136;

  /* other colors */
  --YelloBG: #fff6c7;
  --YelloContrast: #ffcb00;
  --BlueBG: #d3e4fe;
  --BlueContrast: #7d96e5;
  --SkyBlueBG: #d2fcfc;
  --SkyBlueContrast: #1ab8b8;

  /* fontweights */
  --lightweight: 300;
  --regular: 400;
  --bold: 700;

  /* line heights */
  --paraLineHeight: 1.6em;
  --paraLineHeightMobile: 1.3em;
  --bigHeadingLineHeight: 112.11%;

  /* universal paddings */
  --sectionPadding: 0 70px;

  /* heading color */
  --headingColor: #474747;
  --paraColor: rgb(0, 0, 0);
}

/* media querries for variables */
@media (max-width: 992px) {
  :root {
    --sectionPadding: 0 40px;
  }
}

@media (max-width: 540px) {
  :root {
    --sectionPadding: 0 20px;
  }
}

@media (min-width: 1440px) {
  :root {
    --sectionPadding: 0px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(255, 255, 255);
  width: 100vw;
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* scrollbar designs */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0px;
  margin: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255, 20%);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(95, 95, 95);
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--headingColor);
  background: #bbeecb;
}

::selection {
  color: var(--headingColor);
  background: #bbeecb;
}

/* All heading and paragraph universal stylings  */
.LandingHeading {
  color: var(--headingColor);
  font-size: 40px;
  font-weight: var(--bold);
  line-height: var(--bigHeadingLineHeight);
  letter-spacing: 0.05em;
  /* text-transform: uppercase; */
  font-family: "Clash Display", sans-serif;
}

.SmallHeadings {
  color: var(--headingColor);
  font-size: 18px;
  font-weight: 600;
  z-index: 2;
  font-family: "Clash Display", sans-serif;
  margin: 0;
  word-spacing: 1px;
}
.universal-paragraphs {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: var(--paraColor);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.universal-button {
  height: 46px;
  width: fit-content;
  border: none;
  background-color: var(--PrimaryGreen);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 10px 22px;
  z-index: 10;
  font-size: 13px;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border-radius: 100px;
  transition: 0.3s;
  text-wrap: nowrap;
}

.universal-button:hover {
  color: white;
  background: #12aa42;
}

.universalInput,
.universalTextArea {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  background: rgba(242, 242, 242, 0.7);
  border-radius: 10px;
  padding: 0px 16px;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  font-family: "Poppins", sans-serif;
}
.universalInput {
  height: 46px;
}
.universalInput:focus {
  border-bottom: 1px solid var(--PrimaryGreen);
  -webkit-transition: 0.1s;
  transition: 0.5s;
}
.universalTextArea {
  height: 100%;
  padding-top: 10px;
}
.universalInput::placeholder {
  color: rgb(148, 148, 148);
}
.universalTextArea::placeholder {
  color: rgb(148, 148, 148);
}

.universalTextArea:focus {
  border-bottom: 1px solid var(--PrimaryGreen);
  -webkit-transition: 0.1s;
  transition: 0.5s;
}

@media (max-width: 768px) {
  .LandingHeading {
    font-size: 42px;
  }
  .universal-paragraphs {
    font-size: 14px;
  }
  .universal-small-headings {
    font-size: 6vw;
  }
  .breakpoint {
    display: none;
  }
}
@media (max-width: 420px) {
  .LandingHeading {
    font-size: 28px;
  }
  .SmallHeadings {
    font-size: 16px;
  }
  .universal-paragraphs {
    font-size: 14px;
  }
  .universal-small-headings {
    font-size: 7.5vw;
  }
}

.UniversalOuterWrapper {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.UniversalInnerContainer {
  width: 100%;
  max-width: 1366px;
  height: fit-content;
  padding: var(--sectionPadding);
}

.HyperLinks {
  cursor: pointer;
  transition: 0.3s;
}
.HyperLinks:hover {
  text-decoration: underline 2px solid;
}
.dFlex {
  display: flex;
  align-items: center;
}

/* Loader stylings */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  border-radius: 50%;
}

/* .loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
} */

.loader {
  border: 3px solid white;
  border-left-color: transparent;
  width: 18px;
  height: 18px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* image opening css */
.onPhoto {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.image {
  width: auto;
  min-width: 80%;
  max-width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}

/* Landing page video buffering loader */
.LandingVideoBufferLoader {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgb(255, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-color: black;
  padding-bottom: 100px;
}
.LandingVideoBufferLoader::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    100deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 20%,
    rgba(14, 211, 63, 0.192) 50%,
    rgba(227, 227, 227, 0) 80%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation_2 0.9s linear infinite;
}

@keyframes gradient-animation_2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

/* styling fake loader for adding before every page for smooth routing */
.FakeLoaderWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.459);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

}
.FakeLoaderWrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    100deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 20%,
    rgba(255, 255, 255, 0.192) 50%,
    rgba(227, 227, 227, 0) 80%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation_2 0.9s linear infinite;
}
